import React, { useEffect } from "react";
import gsap from "gsap";

const Video = (props) => {
  useEffect(() => {
    var video = document.getElementById("video");

    video.addEventListener("play", (event) => {
      gsap.to("#video", {
        delay: 4,
        duration: 3,
        opacity: 1,
      });
    });

    video.playbackRate = 0.6;
    video.currentTime = 0;
    video.play();
  });

  return (
    <video
      id="video"
      style={{
        display: "inline-block",
        position: "absolute",
        objectFit: "cover",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
      }}
      src="https://realmtest.sfo3.digitaloceanspaces.com/cribs.mp4"
      playsInline
      webkit-playsInline
      muted
      loop
      autoplay
      autoPlay
    />
  );
};
export default Video;
