import React, { useEffect, useState } from "react";
import gsap from "gsap";

import logo from "../images/atelo.gif";

const initColor = "#F00"

const Intake = (props) => {
  useEffect(() => {
    gsap.to("#border", {
      duration: 4,
      opacity: 1,
      clipPath: "circle(100%)",
      ease: "power1.out",
    });

    gsap.to("#framebar", {
      delay: 1,
      duration: 4,
      opacity: 1,
      background: "#FFD96C"
    });


  }, []);

  

  return (
    <>
      <div
        id="border"
        style={{
          zIndex: 30,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          pointerEvents: "none",
          userSelect: "none",
          clipPath: "circle(0%)",
          background:
            "linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.8) 100%)",
        }}
      >
        <div
          style={{
            position: "absolute",
            writingMode: "vertical-rl",
            transform: "rotate(180deg)",
            left: 0,
            margin: "1%",
            top: "40%",
            color: "#FFF",
            cursor: "pointer",
            pointerEvents: "auto",
            userSelect: "auto",
          }}
          onClick={() => alert("about")}
        >
          <h2 id="about">LEARN MORE</h2>
          <div class="pulsatingcircle"></div>
        </div>

        <div
          style={{
            position: "absolute",
            writingMode: "vertical-rl",
            right: 0,
            top: "40%",
            margin: "1%",
            color: "#FFF",
            cursor: "pointer",
            pointerEvents: "auto",
            userSelect: "auto",
          }}
          onClick={() => alert("services")}
        ><h2>JOIN THE CLUB</h2>
          <div class="pulsatingcircle"></div>
        </div>

        <div
          style={{
            position: "absolute",
            left: "50%",
            bottom: 0,
            msTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            margin: "1%",
            color: "#FFF",
            cursor: "pointer",
            pointerEvents: "auto",
            userSelect: "auto",
            display: 'none'
          }}
          onClick={() => alert("test")}
        >
          <h2>JOIN THE CLUB</h2>

          <div class="pulsatingcircle"></div>
        </div>
        <div
          style={{
            position: "relative",
            height: "100%",
            WebkitBoxSizing: "border-box",
            MozBoxSizing: "border-box",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              id: "logo",
              position: "absolute",
              display: "inline-block",
              lineHeight: 1.5,
              top: "-16px",
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
              pointerEvents: "auto",
              userSelect: "auto",
            }}
            onClick={() => alert("logo")}
          >
            <span>
              <img style={{ width: "120px" }} src={logo}></img>
            </span>
          </div>
          {/*
          begin border
          */}
          <div id="frame">
            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                top: 0,
                left: 0,
                width: "calc(40vw - 100px)",
                height: "2px",
                background: initColor,
              }}
            ></div>
            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                top: 0,
                right: 0,
                width: "calc(40vw - 100px)",
                height: "2px",
                background: initColor,
              }}
            ></div>

            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "calc(40vw - 100px)",
                height: "2px",
                background: initColor,
              }}
            ></div>
            <div
              style={{
                display: "inline-block",
                position: "absolute",
                textAlign: "center",
                margin: "auto",
                bottom: "30px",
                width: "100%",
                top: "100%",
              }}
            ></div>
            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                bottom: 0,
                right: 0,
                color: "#F00",
                width: "calc(40vw - 100px)",
                height: "2px",
                background: initColor,
              }}
            ></div>

            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                bottom: 0,
                left: 0,
                color: "#F00",
                width: "2px",
                height: "100%",
                background: initColor,
              }}
            ></div>
            <div
            id="framebar"
              style={{
                zIndex: 12,
                position: "absolute",
                bottom: 0,
                right: 0,
                color: "#F00",
                width: "2px",
                height: "100%",
                background: initColor,
              }}
            ></div>
          </div>

          {/*
          end border
          */}
        </div>
      </div>
    </>
  );
};

export default Intake;
