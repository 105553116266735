import React, { Component, Suspense } from "react"
import { Helmet } from "react-helmet"
import Splash from "../components/Splash";
import Video from "../components/Video";
import Intake from "../components/Intake";
import gsap from "gsap"
import './index.css'

import faviconApple from '../images/apple-touch-icon.png'
import favicon32 from '../images/favicon-32x32.png'
import favicon16 from '../images/favicon-16x16.png'
import faviconICO from '../images/favicon.ico'


const ScheduleContent = (props) => {

  return (
    <><Intake/>
    <Video/>
    
    </>
  );
};



export default class App extends Component {
  componentDidMount() {

    window.ucheIndex = 33

    gsap.to("#splash", {
      delay: 0.3,
      opacity: 1,
      duration: 2.2
    })

  }

  render() {

    return (
      <>
        <div style={{ overflowY: 'hidden', backgroundColor: "#000" }}>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <meta name="theme-color" content="#ffffff" />
            <meta property="og:title" content="atelo world" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://atelo.world" />
            <meta property="og:image" content="https://realmtest.sfo3.digitaloceanspaces.com/atelo.ico" />

            <link rel="icon" type="image/x-icon" href="https://realmtest.sfo3.digitaloceanspaces.com/atelo.ico" />

            <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
            <link rel="shortcut icon" href={faviconICO} />
            <meta name="theme-color" content="#ffffff" />
            <title>Home | Atelo</title>
          </Helmet>

          <ScheduleContent />

        </div>
      </>
    )
  }
}
